import React from 'react';
// import Layout from '../templates/Layout';
import SEO from '../components/SEO';
import { Link } from 'gatsby';
import { useSiteMetadata } from '../hooks/useSiteMetadata';

const SolicitudEnviadaPage = () => {
  const {
    primaryColor,
    // primaryHover,
    primaryBg,
    primaryBgHover,
  } = useSiteMetadata();
  return (
    <section className="h-screen py-20 bg-stripes">
      <SEO
        pageTitle="Gracias por enviar solicitud"
        description="V&A Roofing, Inc. ofrece estimado gratis para el mejor precio de sellado de techo en Puerto Rico. Completa el formulario de cotización o llama al 787-597-6536."
      />

      <div className="mx-4">
        <div className="container max-w-lg mx-auto space-y-10 text-center">
          <h1 className="text-4xl font-extrabold">
            <span className={primaryColor}>¡Gracias!</span> Tu solicitud de
            cotización fue enviada exitosamente.
          </h1>
          <p className="text-lg">Te estaremos respondiendo prontamente.</p>
          <Link
            // onClick={trackResidentialHeroButton}
            to="/cotizacion"
            role="button"
            className={`w-full text-center flex items-center justify-center px-8 py-4 border border-transparent text-base leading-6 font-medium rounded-md text-white ${primaryBg} hover:${primaryBgHover} focus:outline-none focus:shadow-outline transition duration-150 ease-in-out lg:py-4 lg:text-lg lg:px-10 uppercase font-bold `}
          >
            Regresar a la página anterior
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SolicitudEnviadaPage;
